/* eslint-disable react/self-closing-comp */
/* eslint-disable */
import 'core-js/features/iterator/for-each';
import 'core-js/features/dom-collections/for-each';
import 'core-js/features/object/entries'; // Required for react-spring
import 'core-js/features/object/from-entries'; // Required for react-spring
import 'core-js/features/object/values'; // Required for react-spring
import 'core-js/features/array/flat-map'; // Required for react-spring
import 'core-js/features/string/repeat';
import 'core-js/features/dom-collections/for-each';
import 'core-js/features/array/flat';
import React, { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { Helmet } from 'react-helmet';
import { ApolloProvider } from '@apollo/client';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { store } from 'sterling-redux/store';
import { client } from 'apollo';
import './assets/styles/main.css';
import { oemNameFromShortCode, appName, CurrentOem, getToken } from 'utility';
import CssBaseline from '@mui/material/CssBaseline';
import { URLLanguageProvider, useLanguage } from 'suites/sterling/app/context/language';
import {
  AuthorizationConsumer,
  AuthorizationProvider,
} from 'suites/sterling/app/context/authorization';
import { URLSuiteProvider, useSuite } from 'suites/sterling/app/context/suite';
import { AppFontStyles, AppStyledWrapper } from 'styled.App';
import Main from 'views/main/Main';
import themes from 'v5-theme/create-themes';
import ComplianceAppWithData from 'suites/compliance/ComplianceApp';
import { ThemeProvider as MuiV5ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { LoginScreen } from 'suites/sterling/pages/login/LoginScreen';
import AdherenceAppWithData from 'suites/adherence/AdherenceApp';
import { GlobalStyles } from '@mui/material';
import { Loading } from 'components';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import HaccAdherenceEnd from 'views/pages/legacy/hacc.AdherenceEnd';
import HaccSterlingEnd from 'views/pages/legacy/hacc.SterlingEnd';
import HaccComplianceEnd from 'views/pages/legacy/hacc.ComplianceEnd';


// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: any, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

/* eslint-enable */

function Head() {
  const suite = useSuite();
  const lang = useLanguage();
  const oemName = oemNameFromShortCode(CurrentOem);
  const productName =
    suite === 'PORTAL'
      ? appName[lang]
      : suite === 'ADHERENCE'
        ? 'Marketing Adherence'
        : 'Marketing Compliance Program';

  return (
    <Helmet>
      <title>{`${oemName} ${productName}`}</title>
      <meta name="description" content={`${oemName} ${productName}`} />
      <link rel="shortcut icon" href={`/favicon_${oemName.toLowerCase()}.ico`} />
    </Helmet>
  );
}

function StyleInjector({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  return (
    <AppStyledWrapper>
      {!location.pathname.includes('vendor-dashboard') ? (
        <AppFontStyles>{children}</AppFontStyles>
      ) : (
        children
      )}
    </AppStyledWrapper>
  );
}

export function PortalApp() {
  return (
    <Provider store={store}>
      {store.getState().activeSelections?.error ? (
        <p style={{ margin: '20px 20px' }}>No configuration found for this OEM.</p>
      ) : (
        <MuiV5ThemeProvider theme={themes[CurrentOem]}>
          <StyleInjector>
            <Main />
          </StyleInjector>
        </MuiV5ThemeProvider>
      )}
    </Provider>
  );
}

function ComplianceApp() {
  return (
    <MuiV5ThemeProvider theme={themes[CurrentOem]}>
      <ComplianceAppWithData />
    </MuiV5ThemeProvider>
  );
}
function AdherenceApp() {
  return (
    <MuiV5ThemeProvider theme={themes[CurrentOem]}>
      <GlobalStyles
        styles={(theme) => ({
          body: { fontFamily: theme.typography.fontFamily },
        })}
      />
      <AdherenceAppWithData />
    </MuiV5ThemeProvider>
  );
}

export function SterlingWrapper() {
  const isHacc = CurrentOem === 'HACC';
  const token = getToken();
  const isBlvd = token?.username?.endsWith('@blvdagency.com');

  return (
    <AuthorizationProvider>
      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5WKQ3RX2"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>
      <Router>
        <URLSuiteProvider>
          <URLLanguageProvider>
            <Head />
            <ApolloProvider client={client}>
              <ApolloHooksProvider client={client}>
                <CssBaseline />
                <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                  <Suspense fallback={<Loading />}>
                    <AuthorizationConsumer>
                      {(authContext) =>
                        authContext.authorized ? (
                          <Switch>
                            {isHacc && isBlvd && (
                              <Route path="/:fr?/adherence">
                                <AdherenceApp />
                              </Route>
                            )}
                            {isHacc && (
                              <Route path="/:fr?/adherence">
                                <HaccAdherenceEnd />
                              </Route>
                            )}
                            {isHacc && isBlvd && (
                              <Route path="/:fr?/reportingtool">
                                <ComplianceApp />
                              </Route>
                            )}
                            {isHacc && (
                              <Route path="/:fr?/reportingtool">
                                <HaccComplianceEnd />
                              </Route>
                            )}
                            {!isHacc && (
                              <Route path="/">
                                <PortalApp />
                              </Route>
                            )}
                            {isHacc && isBlvd && (
                              <Route path="/">
                                <PortalApp />
                              </Route>
                            )}
                            {isHacc && !isBlvd && (
                              <Route path="/">
                                <HaccSterlingEnd />
                              </Route>
                            )}
                          </Switch>
                        ) : (
                          <MuiV5ThemeProvider theme={themes[CurrentOem]}>
                            <LoginScreen />
                          </MuiV5ThemeProvider>
                        )
                      }
                    </AuthorizationConsumer>
                  </Suspense>
                </StyleSheetManager>
              </ApolloHooksProvider>
            </ApolloProvider>
          </URLLanguageProvider>
        </URLSuiteProvider>
      </Router>
    </AuthorizationProvider>
  );
}

if (process.env.NODE_ENV !== 'test') {
  createRoot(document.getElementById('root') as Element).render(<SterlingWrapper />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* eslint-disable */
if ((module as any).hot) {
  (module as any).hot.accept();
}
